<template>
  <nav v-if="isMoreThanOnePage">
    <ul class="pagination">
      <li class="page-item" :class="{'disabled': isFirstPage}" @click="!isFirstPage ? goToPage(1) : null">
        <button class="page-link" rel="prev">
          <i class="icon-arrow-left">
              <span class="path1"></span>
              <span class="path2"></span>
          </i>
        </button>
      </li>

      <li v-if="numberOfButtonsBeforeCurrent !== 0" v-for="index in numberOfButtonsBeforeCurrent" class="page-item" @click="goToPage(this.pager.currentPage - index)">
        <button class="page-link">{{ this.pager.currentPage - (numberOfButtonsBeforeCurrent - (index - 1)) }}</button>
      </li>

      <li class="page-item active">
        <button class="page-link">{{ this.pager.currentPage }}</button>
      </li>

      <li v-for="index in numberOfButtonsAfterCurrent" class="page-item" @click="goToPage(this.pager.currentPage + index)">
        <button class="page-link">{{ this.pager.currentPage + index }}</button>
      </li>

      <li class="page-item" :class="{'disabled': isLastPage}" @click="!isLastPage ? goToPage(this.pager.currentPage + 1) : null">
        <button class="page-link" rel="next">
          <i class="icon-arrow-right">
              <span class="path1"></span>
              <span class="path2"></span>
          </i>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pager: {},
  },
  data() {
    return {

    }
  },
  methods: {
    goToPage(index) {
      this.$emit('go-to-page', index);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  computed: {
    isMoreThanOnePage() {
      return this.pager.totalItemCount > this.pager.itemNumberPerPage
    },
    isFirstPage() {
      return this.pager.currentPage <= 1;
    },
    maxPageCount() {
      return Math.ceil(this.pager.totalItemCount / this.pager.itemNumberPerPage);
    },
    isLastPage() {
      return this.pager.currentPage >= this.maxPageCount;
    },
    numberOfButtonsBeforeCurrent() {
      let totalButtons = this.pager.buttonLimit > this.maxPageCount ? this.maxPageCount : this.pager.buttonLimit;

      if (totalButtons % 2 === 0) {
        totalButtons = totalButtons - 1
      }

      totalButtons = totalButtons - 1

      let beforeButtonCount = totalButtons / 2;

      if ((this.pager.currentPage - beforeButtonCount) < 1) {
        beforeButtonCount = this.pager.currentPage - 1;
      }

      let afterButtonCount = totalButtons / 2;

      if ((this.pager.currentPage + afterButtonCount) > this.maxPageCount) {
        afterButtonCount = this.maxPageCount - this.pager.currentPage;

        beforeButtonCount = beforeButtonCount + ((totalButtons / 2) - afterButtonCount)
      }

      return beforeButtonCount;
    },
    numberOfButtonsAfterCurrent() {
      let totalButtons = this.pager.buttonLimit > this.maxPageCount ? this.maxPageCount : this.pager.buttonLimit;

      if (totalButtons % 2 === 0) {
        totalButtons = totalButtons - 1
      }

      totalButtons = totalButtons - 1

      let afterButtonCount = totalButtons / 2;

      if ((this.pager.currentPage + afterButtonCount) > this.maxPageCount) {
        afterButtonCount = this.maxPageCount - this.pager.currentPage;
      }

      let beforeButtonCount = totalButtons / 2;

      if ((this.pager.currentPage - beforeButtonCount) < 1) {
        beforeButtonCount = this.pager.currentPage - 1;

        afterButtonCount = afterButtonCount + ((totalButtons / 2) - beforeButtonCount)
      }

      return afterButtonCount;
    }
  }
}
</script>

<style scoped>

</style>