<template>
  <div class="facet-element">
    <div class="row mb-2 align-items-center">
      <div class="col-8">
        {{ label }}
      </div>
      <div class="col-4">
        <Toggle class="cursor-pointer" @change="updateContext(value)" :value="isInContext(value)" />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../../../../store.js'
import Toggle from '@vueform/toggle'

export default {
  name: "CheckBoxType",
  props: ['name', 'label', 'value'],
  components: {
    Toggle
  },
  data() {
    return {
      store,
    }
  },
  methods: {
    isInContext(value) {
      if(!this.store.context.hasOwnProperty(this.name) || this.store.context[this.name] === null) {
        return false;
      }

      return store.context[this.name].includes(value);
    },
    updateContext(value) {
       if(!this.store.context.hasOwnProperty(this.name)) {
         console.info('Could not find filter ', this.name);
         return
       }

       if (this.store.context[this.name] === null) {
         this.store.context[this.name] = [];
       }

       if(this.store.context[this.name].includes(value)) {
          this.store.context[this.name] = this.store.context[this.name].filter(key => key !== value)
       } else {
          this.store.context[this.name] = [...this.store.context[this.name], value]
       }

       this.store.updateSearchResults();
    }
  }
}
</script>

<style scoped>

</style>
