<template>
  <div id="loaderWrapper">
    <div class="card-header border-0 pt-4 d-flex justify-content-between align-items-center pb-3">
      <h1>Jaarwidget</h1>
      <div class="form-select-wrapper">
        <select class="p-3 form-select form-select-lg form-select-solid w-20" @change="getYearCalendarData($event.target.value)">
          <option v-if="accommodationEntities.length > 0" v-for="entity in accommodationEntities" :value="entity.uuid">{{ entity.label }}</option>
        </select>
      </div>
    </div>
    <Calendar
      :data-source="dataSource"
      :year="currentYear"
      :language="language"
      :context-menu-items="contextMenuItems"
      @click-day="clickDay"
      @year-changed="changeSelectedYear"
    ></Calendar>

    <div v-if="show" class="year-calendar-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Info</h1>
          <button @click="closeModal" type="button" class="btn btn-light close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-12">
                <h2 class="h2 text-capitalize pb-2">{{ currentName }}</h2>
              </div>
            </div>
            <h3 class="h5">{{ currentStartDateFormatted }} tot {{ currentEndDateFormatted }}</h3>
          </form>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" type="button" class="btn btn-secondary">Sluiten</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="spinner-overlay">
    <div class="spinner"></div>
  </div>

</template>

<script setup>
import {ref, onMounted} from 'vue';
import Calendar from './YearCalendarWidget.vue';

const language = ref('nl');

const currentStartDate = ref(null);
const currentEndDate = ref(null);
const currentStartDateFormatted = ref(null);
const currentEndDateFormatted = ref(null);
const currentName = ref(null);
const selectedAccommodation = ref(null);

const show = ref(false);
const isLoading = ref(false);

const dataSource = ref([]);
const accommodationEntities = ref([]);

const currentYear = ref(new Date().getFullYear());

function changeSelectedYear(year) {
  if (currentYear.value != year.currentYear){
    currentYear.value = year.currentYear;
    getYearCalendarData();
  };
};

async function getYearCalendarData(id) {

  if (!id && !selectedAccommodation.value) {
    selectedAccommodation.value = accommodationEntities.value[0].uuid;
  }

  if (id) {
    selectedAccommodation.value = id;
  }

  isLoading.value = true;

  const url = `/nl/manager-portal/accommodations/${selectedAccommodation.value}/calendar/year/${currentYear.value}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new ResponseError('Bad fetch response', response);
    }

    const responseData = await response.json();

    responseData.forEach((data) => {
      switch (data.type) {
        case 'booking':
          data.color = '#fdb9b9'; // reddish pink
          break;
        case 'unavailable':
          data.color = '#888787'; // gray - black
          break;
        default: 
          // should not happen, only if data.type is not one of the above types
          data.color = '#edf7ff'; // CJT light blue
          break;
      }

      const formattedStartDate = new Date(data.startDate).toISOString();
      const formattedEndDate = new Date(data.endDate).toISOString();

      data.name = data.translatedType;
      data.location = data.source;
      data.startDate = new Date(formattedStartDate);
      data.startDate.setHours(0, 0, 0, 0);
      data.endDate = new Date(formattedEndDate);
      data.endDate.setHours(0, 0, 0, 0);
    });

    dataSource.value = responseData;
  } catch (error) {
    dataSource.value = [];
    isLoading.value = false;
  } finally {
    isLoading.value = false;
  }
}

const getYearCalendarAccommodationEntities = async () => {
  try {
    const response = await fetch('/nl/manager-portal/accommodations/calendar/year/list');

    if (!response.ok) {
        throw new ResponseError('Bad fetch response', response);
    }

    accommodationEntities.value = await response.json();
    await getYearCalendarData();

  } catch (error) {
    accommodationEntities.value = [];
  } finally {
    isLoading.value = false;
  }
}

const contextMenuItems = [
  {
    click: (evt) => {
      currentStartDate.value = evt.startDate;
      currentEndDate.value = evt.endDate;
      currentName.value = evt.translatedType;
      show.value = true;
    },
  },
];

const clickDay = (event) => {
  if (event.events.length > 0) {
    const selectedLocation = event.events[0];
    currentStartDateFormatted.value = selectedLocation.startDate.toISOString().substring(0, 10);
    currentEndDateFormatted.value = selectedLocation.endDate.toISOString().substring(0, 10);
    currentName.value = selectedLocation.translatedType;
    show.value = true;
  }
};

const closeModal = () => {
  show.value = false;
};

onMounted(async () => {
  await getYearCalendarAccommodationEntities();
  await getYearCalendarData();
})
</script>
