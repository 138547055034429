import { createApp } from 'vue';
import VueClickAway from "vue3-click-away";

import GalleryWidget from './widgets/GalleryWidget';
import AccommodationAvailabilityWidget from './widgets/AccommodationAvailabilityWidget';
import AccommodationSearchWidget from './widgets/AccommodationSearchWidget';
import MiniCalendarWidget from "./widgets/MiniCalendarWidget";
import BookingRangeSelectorWidget from "./widgets/BookingRangeSelectorWidget";
import AccommodationPricesWidget from "./widgets/AccommodationPricesWidget";
import DailyOvernightStatisticsWidget from "./widgets/DailyOvernightStatisticsWidget";
import DynamicCostsFormWidget from "./widgets/DynamicCostsFormWidget";
import InteractiveTableWidget from "./widgets/InteractiveTableWidget";
import StarSelectorWidget from "./widgets/StarSelectorWidget";
import YearCalendarWidgetWrapper from "./widgets/YearCalendarWidgetWrapper";

const accommodationAvailabilityWidgetEl = document.querySelector('#accommodationAvailabilityWidget');
const accommodationSearchWidgetEl = document.querySelector('#accommodationSearchWidget');
const galleryWidgetEl = document.querySelector('#galleryWidget');
const miniCalendarWidgetEl = document.querySelector('#miniCalendarWidget');
const bookingRangeSelectorWidgetEl = document.querySelector('#bookingRangeSelectorWidget');
const accommodationPricesWidgetEl = document.querySelector('#accommodationPricesWidget');
const dailyOvernightStatisticsWidgetEl = document.querySelector('#dailyOvernightStatisticsWidget');
const dynamicCostsFormWidgetElements = document.querySelectorAll('.dynamicCostsFormWidget');
const interactiveTableWidgetElement = document.querySelector('#interactiveTableWidget');
const starSelectorWidgetElement = document.querySelector('#starSelectorWidget');
const yearCalendarWidgetWrapperElement = document.querySelector('#yearCalendarWidgetWrapper');

    if (accommodationAvailabilityWidgetEl) {
        const accommodationAvailabilityWidget = createApp(AccommodationAvailabilityWidget, { ...accommodationAvailabilityWidgetEl.dataset })
        accommodationAvailabilityWidget.mount("#accommodationAvailabilityWidget");
    }

    if (accommodationSearchWidgetEl) {
        const accommodationSearchWidget = createApp(AccommodationSearchWidget, { ...accommodationSearchWidgetEl.dataset })
        accommodationSearchWidget.mount("#accommodationSearchWidget");
    }

    if (galleryWidgetEl) {
        const galleryWidget = createApp(GalleryWidget,{ ...galleryWidgetEl.dataset })
        galleryWidget.mount("#galleryWidget");
    }

    if (miniCalendarWidgetEl) {
        const miniCalendarWidget = createApp(MiniCalendarWidget,{ ...miniCalendarWidgetEl.dataset })
        miniCalendarWidget.mount("#miniCalendarWidget");
    }

    if (bookingRangeSelectorWidgetEl) {
        const bookingRangeSelectorWidget = createApp(BookingRangeSelectorWidget,{ ...bookingRangeSelectorWidgetEl.dataset })
        bookingRangeSelectorWidget.use(VueClickAway);
        bookingRangeSelectorWidget.mount("#bookingRangeSelectorWidget");
        window.BookingRangeSelectorWidget = bookingRangeSelectorWidget;
    }

    if (accommodationPricesWidgetEl) {
        const accommodationPricesWidget = createApp(AccommodationPricesWidget,{ ...accommodationPricesWidgetEl.dataset })
        accommodationPricesWidget.mount("#accommodationPricesWidget");
    }

    if (dailyOvernightStatisticsWidgetEl) {
        const dailyOvernightStatisticsWidget = createApp(DailyOvernightStatisticsWidget,{ ...dailyOvernightStatisticsWidgetEl.dataset })
        dailyOvernightStatisticsWidget.mount("#dailyOvernightStatisticsWidget");
    }

    if (dynamicCostsFormWidgetElements) {
        dynamicCostsFormWidgetElements.forEach(dynamicCostsFormWidgetEl => {
            const id = dynamicCostsFormWidgetEl.getAttribute('id');
            const dynamicCostsFormWidget = createApp(DynamicCostsFormWidget, { ...dynamicCostsFormWidgetEl.dataset })
            dynamicCostsFormWidget.mount(`#${id}`);
        });
    }

    if (interactiveTableWidgetElement) {
        const interactiveTableWidget = createApp(InteractiveTableWidget,{ ...interactiveTableWidgetElement.dataset });
        interactiveTableWidget.mount("#interactiveTableWidget");
    }

    if (starSelectorWidgetElement) {
        const starSelectorWidget = createApp(StarSelectorWidget,{ ...starSelectorWidgetElement.dataset });
        starSelectorWidget.mount("#starSelectorWidget");
    }

    if (yearCalendarWidgetWrapperElement) {
        const yearCalendarWidgetWrapper = createApp(YearCalendarWidgetWrapper,{ ...yearCalendarWidgetWrapperElement.dataset });
        yearCalendarWidgetWrapper.mount("#yearCalendarWidgetWrapper");
    }

