<template>
  <div class="facet-element">
    <label v-for="option in options" @change="updateContext(option)" class="checkbox">
      {{ option.label }}
      <input :checked="isInContext(option)" :value="option.key" type="checkbox">
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
import { store } from '../../../../store.js'

export default {
  name: "CheckBoxesType",
  props: ['name', 'options'],
  data() {
    return {
      store,
    }
  },
  methods: {
    isInContext(option) {
      if(!this.store.context.hasOwnProperty(this.name) || this.store.context[this.name] === null) {
        return false;
      }

      return store.context[this.name].includes(option.key);
    },
    updateContext(option) {
       if(!this.store.context.hasOwnProperty(this.name)) {
         console.info('Could not find filter ', this.name);
         return
       }

       if (this.store.context[this.name] === null) {
         this.store.context[this.name] = [];
       }

       if(this.store.context[this.name].includes(option.key)) {
          this.store.context[this.name] = this.store.context[this.name].filter(key => key !== option.key)
       } else {
          this.store.context[this.name] = [ ...this.store.context[this.name], option.key ]
       }

       this.store.updateSearchResults();
    }
  }
}
</script>

<style scoped>

</style>
