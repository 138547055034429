<template>
  <div>
    <button class="d-block d-lg-none btn-toggle-mobile-facets" @click="toggleFilter(true)">
      <i class="icon-filter"></i>
      Filter resultaten
    </button>
    <div class="accommodation-facet-list" v-bind:class="{ 'mobile-view-active': isMobileViewActive }">
      <div class="d-flex d-lg-none justify-content-between align-items-center mb-3">
        <h4>Filter resultaten</h4>
        <i class="icon-close" @click="toggleFilter(false)"></i>
      </div>
      <aside class="facet-filter-container">
        <facet
            v-for="(facetGroup, name, index) in facetGroups"
            :title="name"
        >
          <template v-for="facet in facetGroup">
            <CheckBoxType v-if="facet.type === filterTypes.CHECKBOX" :name="facet.name" :label="facet.label" :value="facet.values[0].key"></CheckBoxType>
            <CheckBoxesType v-if="facet.type === filterTypes.CHECKBOXES" :name="facet.name" :options="facet.values"></CheckBoxesType>
            <SelectType v-if="facet.type === filterTypes.SELECT" :name="facet.name" :options="facet.values"></SelectType>
            <NumberType v-if="facet.type === filterTypes.NUMBER" :name="facet.name" :label="facet.label"></NumberType>
          </template>
        </facet>
      </aside>
    </div>
  </div>
</template>

<script>
import Facet from "./Facet";
import { store } from '../../../store.js'
import { filterTypes } from "../../../variables";
import CheckBoxesType from "./FacetTypes/CheckBoxesType";
import CheckBoxType from "./FacetTypes/CheckBoxType";
import SelectType from "./FacetTypes/SelectType";
import NumberType from "./FacetTypes/NumberType";

export default {
  name: "FacetList",
  components: { Facet, CheckBoxType, CheckBoxesType, SelectType, NumberType },
  data() {
    return {
      store,
      filterTypes,
      isMobileViewActive: false,
      isSelfCook: false,
      isFullBoard: false,
    }
  },
  computed: {
    facetGroups() {
      return this.store.facets.reduce(
          (facetGroups, facet) => ({
            ...facetGroups,
            [facet['group']]: [
              ...(facetGroups[facet['group']] || []),
              facet,
            ],
          }),
          {},
      );
    }
  },
  methods: {
    toggleFilter(state) {
      this.isMobileViewActive = state;
      document.querySelector('body').classList.toggle('no-scrolling')
    }
  }
}
</script>

<style scoped>

</style>
