<template>
  <div id="calendar"></div>
</template>

<script setup>
import { ref, watch } from 'vue';
import JsCalendar from 'js-year-calendar';
import 'js-year-calendar/dist/js-year-calendar.css';
import 'js-year-calendar/locales/js-year-calendar.nl';

const props = defineProps({
  contextMenuItems: Array,
  dataSource: Array,
  language: String,
  year: Number,
});

const emits = defineEmits([
  'click-day',
  'year-changed',
]);

const calendar = ref(null);

const changeYear = (year) => {
  emits('year-changed', year);
};

const rerender = () => {
  calendar.value = new JsCalendar(document.getElementById('calendar'), {
    contextMenuItems: props.contextMenuItems,
    dataSource: props.dataSource,
    language: props.language,
    alwaysHalfDay: true,
    displayHeader: true,
    enableContextMenu: false,
    enableRangeSelection: false,
    style: 'background',
    startYear: props.year,

    // Events
    clickDay: (e) => emits('click-day', e),
    yearChanged: (e) => changeYear(e),
  });
};

watch(
  () => [
    props.contextMenuItems,
    props.dataSource,
    props.language,
  ],
  () => {
    rerender();
  }
);

</script>
