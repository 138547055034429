<template>
  <div class="booking-date-widget">
    <div class="range-display-component">
      <div class="boxed-icon green">
        <i class="icon-calendar"></i>
      </div>
      <div class="start-date" @click="toggleModal">
        <span v-if="!selectedDate.start">{{ vueTranslator('common.start_date') }}</span>
        <span v-if="selectedDate.start">{{ dateFormat(selectedDate.start) }}</span>
      </div>
      <svg viewBox="0 0 330 330">
          <path d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255  s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0  c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z" />
      </svg>
      <div class="end-date" :class="{ active: selectedDate.end }" @click="toggleModal">
        <span v-if="!selectedDate.end">{{ vueTranslator('common.end_date') }}</span>
        <span v-if="selectedDate.end">{{ dateFormat(selectedDate.end) }}</span>
      </div>
      <div class="btn-clear" @click="clearSelection" :class="{ hidden: !selectedDate.start && !selectedDate.end }">
        <i class="icon-close"></i>
      </div>
    </div>
    <div class="modal-component" v-click-away="modalOpenState ? toggleModal : null" :class="{ hidden: !modalOpenState }">
      <DatePicker v-model="selectedDate"
                  :attributes="attrs"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :disabled-dates="disabledDates"
                  :drag-attribute="selectedAttr"
                  :select-attribute="selectedAttr"
                  :model-config="modelConfig"
                  is-range
                  mode="range"
                  @dayclick="dayClickEvent"
                  @drag="dragEvent"
                  locale="nl"
      />
    </div>
    <div v-if="checkInTimeName.length > 0 && checkOutTimeName.length > 0" class="row mt-4 check-in-out-times">
      <div class="col-6">
        <div class="form-group mb-4">
          <label>{{ vueTranslator('common.check-in-time') }}</label>
          <Multiselect
              :placeholder="vueTranslator('common.select')"
              :required="true"
              :disabled="!checkInTimeOptions.length"
              class="form-control form-control-lg form-control-solid"
              :options="checkInTimeOptions"
              v-model="selectedCheckInTime"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mb-4">
          <label>{{ vueTranslator('common.check-out-time') }}</label>
          <Multiselect
              :placeholder="vueTranslator('common.select')"
              :required="true"
              :disabled="!checkOutTimeOptions.length"
              class="form-control form-control-lg form-control-solid"
              :options="checkOutTimeOptions"
              v-model="selectedCheckOutTime"
          />
        </div>
      </div>
    </div>
  </div>
  <input type="hidden" :name="checkInTimeName" :value="selectedCheckInTime">
  <input type="hidden" :name="checkOutTimeName" :value="selectedCheckOutTime">
  <input type="hidden" class="js_fetch-booking-data" id="create_option_checkInDate" :name="startName" :value="selectedDate.start ? dateFormat(selectedDate.start) : ''">
  <input type="hidden" class="js_fetch-booking-data" id="create_option_checkOutDate" :name="endName" :value="selectedDate.end ? dateFormat(selectedDate.end) : ''">
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue';
import { Calendar, DatePicker } from 'v-calendar';
import Multiselect from '@vueform/multiselect';
import { vueTranslator } from '../mixins.js';


const props = defineProps({
  startName: {
    type: String,
    required: true,
  },
  endName: {
    type: String,
    required: true,
  },
  checkInDate: {
    type: String,
    required: false,
  },
  checkOutDate: {
    type: String,
    required: false,
  },
  checkInTimeName: {
    type: String,
    required: false,
  },
  checkOutTimeName: {
    type: String,
    required: false,
  },
  checkInTimeOptionsJson: {
    type: String,
    required: false,
  },
  checkOutTimeOptionsJson: {
    type: String,
    required: false,
  },
  bookedDatesJson: {
    type: String,
    required: false,
    default: '[]',
  },
  bookingSlotsJson: {
    type: String,
    required: false,
    default: '[]',
  },
  reverseDate: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const modalOpenState = ref(false);
const tmp = ref(null);
const modelConfig = ref({
  timeAdjust: '12:00:00',
});
const bookedDates = ref([]);
const bookingSlots = ref([]);
const selectedAttr = ref({
  highlight: {
    contentClass: 'selected-content',
    class: 'selected',
  },
});
const attrs = ref([]);
const selectedDate = ref({
  start: null,
  end: null,
});
const selectedDateTemp = ref({
  start: null,
  end: null,
});
const minDate = ref(new Date(Date.now()));
const maxDate = ref(null);
const disabledDates = ref([]);
const checkInTimeOptions = ref([]);
const checkOutTimeOptions = ref([]);
const selectedCheckInTime = ref(null);
const selectedCheckOutTime = ref(null);

const initFormListeners = () => {
  window.updateIvvCheckbox();
  const reservationGroupElement = document.querySelector('#create_option_group');
  const reservationCapacityElement = document.querySelector('#create_option_amountOfPersons');
  const reservationIvvElement = document.querySelector('#create_option_ivvBooking');

  if (reservationGroupElement) {
    reservationGroupElement.addEventListener('change', fetchData);
  }

  if (reservationIvvElement) {
    reservationIvvElement.addEventListener('input', fetchData);
  }

  if (reservationCapacityElement) {
    let timer;
    const waitTime = 500;

    reservationCapacityElement.addEventListener('input', (event) => {
      clearTimeout(timer);
      timer = setTimeout(fetchData, waitTime);
    });
  }
};

const fetchData = async () => {
  await window.updateIvvCheckbox();
  if (selectedDate.value.start && selectedDate.value.end) {
    const renewedReservationData = await window.fetchReservationData();

    if (renewedReservationData && renewedReservationData.hasOwnProperty('checkInTimes') && renewedReservationData.hasOwnProperty('checkOutTimes')) {
      setCheckInOutTimeSelectors(renewedReservationData.checkInTimes, renewedReservationData.checkOutTimes);
    }
  }
};

const dateFormat = (date) => {
  const d = date.getDate();
  const m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();
  return '' + (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
};

const clearSelection = () => {
  selectedCheckInTime.value = null;
  selectedCheckOutTime.value = null;

  if (props.reverseDate) {
    minDate.value = null;
    maxDate.value = new Date(Date.now());
  } else {
    minDate.value = new Date(Date.now());
    maxDate.value = null;
  }

  disabledDates.value = bookedDates.value;
  selectedDate.value.start = null;
  selectedDate.value.end = null;

  setCheckInOutTimeSelectors(JSON.parse(props.checkInTimeOptionsJson), JSON.parse(props.checkOutTimeOptionsJson));
};

const toggleModal = () => {
  modalOpenState.value = !modalOpenState.value;
};

const dayClickEvent = (e) => {

  if (!selectedDateTemp.value.start) {
    selectedDate.value.start = e.date;
    selectedDateTemp.value.start = e.date;
  } else {
    selectedDate.value.end = e.date;
    selectedDateTemp.value.end = e.date;
  }


  bookingSlots.value.forEach((bookingSlot) => {
    if (selectedDate.value.end > bookingSlot.start && selectedDate.value.end < bookingSlot.end) {
      selectedDate.value.end = bookingSlot.end;
    }

    if (selectedDate.value.start > bookingSlot.start && selectedDate.value.start < bookingSlot.end) {
      selectedDate.value.start = bookingSlot.start;
    }
  });
  const clickedDate = e.date;

  if (clickedDate.toDateString() === tmp.value) {
    clearSelection();
  }
  tmp.value = clickedDate.toDateString();

  if (selectedDate.value.start && selectedDate.value.end && selectedDateTemp.value.end === e.date) {
    modalOpenState.value = false;

    selectedDateTemp.value.start = null;
    selectedDateTemp.value.end = null;
  }
};

const dragEvent = (e) => {
  if (bookedDates.value.map((booked) => booked.start.toDateString()).includes(e.start.toDateString())) {
    console.info('exception cannot start from booking start');
    return;
  }

  let nextAvailableDate = null;
  bookedDates.value.some((bookedDate) => {
    if (bookedDate.start >= e.start) {
      nextAvailableDate = bookedDate;
    }
    return bookedDate.start >= e.start;
  });

  disabledDates.value = null;
  minDate.value = e.start;
  if (nextAvailableDate) {
    maxDate.value = nextAvailableDate.start;
  }
};

const setStylingOnBookedDates = () => {
  const startDates = bookedDates.value.map((booking) => ({
    highlight: {
      contentClass: 'booking-start-date',
      class: 'booking-start-date',
    },
    dates: booking.start,
  }));

  const booked = bookedDates.value.map((booking) => ({
    highlight: {
      contentClass: 'booked-date',
      class: 'booked-date',
    },
    dates: { start: booking.start, end: booking.end },
  }));

  const bookingSlotsStarts = bookingSlots.value.map((bookingSlot) => ({
    highlight: {
      contentClass: 'booking-slot-date-start',
      class: 'booking-slot-date-start',
    },
    dates: bookingSlot.start,
  }));

  const bookingSlotsEnds = bookingSlots.value.map((bookingSlot) => ({
    highlight: {
      contentClass: 'booking-slot-date-end',
      class: 'booking-slot-date-end',
    },
    dates: bookingSlot.end,
  }));

  const bookingSlotDays = bookingSlots.value
    .filter((bookingSlot) => {
      const startDate = new Date(bookingSlot.start);
      return startDate.setDate(startDate.getDate() + 1) < bookingSlot.end;
    })
    .map((bookingSlot) => {
      const startDate = new Date(bookingSlot.start);
      const endDate = new Date(bookingSlot.end);

      return {
        highlight: {
          contentClass: 'booking-slot-date',
          class: 'booking-slot-date',
        },
        popover: {
          label: `${vueTranslator('reservations.booking_slot_popup_message')} ${dateFormat(bookingSlot.start)} - ${dateFormat(bookingSlot.end)}`,
          hideIndicator: true,
        },
        dates: { start: startDate.setDate(startDate.getDate() + 1), end: endDate.setDate(endDate.getDate() - 1) },
      };
    });

  attrs.value = [...booked, ...bookingSlotDays, ...bookingSlotsStarts, ...bookingSlotsEnds, ...startDates];
};

const setDisabledStateOnBookedDates = () => {
  disabledDates.value = bookedDates.value.map((bookedPeriod) => {
    let endDateMinusLastDay = bookedPeriod.end.setDate(bookedPeriod.end.getDate() - 1);

    if (endDateMinusLastDay <= bookedPeriod.end) {
      endDateMinusLastDay = bookedPeriod.end;
    }

    return {
      start: bookedPeriod.start,
      end: endDateMinusLastDay,
    };
  });
};

const setCheckInOutTimeSelectors = (checkInTimeData, checkOutTimeData) => {
  checkInTimeOptions.value = Object.entries(checkInTimeData).map(([key, value]) => ({
    value: value,
    label: value,
  }));

  checkOutTimeOptions.value = Object.entries(checkOutTimeData).map(([key, value]) => ({
    value: value,
    label: value,
  }));
};

onMounted(() => {
  initFormListeners();

  if (props.reverseDate) {
    minDate.value = null;
    maxDate.value = new Date(Date.now());
  }

  bookingSlots.value = JSON.parse(props.bookingSlotsJson).map((bookingSlot) => ({
    start: new Date(bookingSlot.start),
    end: new Date(bookingSlot.end),
  }));

  bookedDates.value = JSON.parse(props.bookedDatesJson).map((bookedDate) => ({
    start: new Date(bookedDate.start),
    end: new Date(bookedDate.end),
  }));

  setCheckInOutTimeSelectors(JSON.parse(props.checkInTimeOptionsJson), JSON.parse(props.checkOutTimeOptionsJson));
  setDisabledStateOnBookedDates();
  setStylingOnBookedDates();
});

watch(selectedDate, async (val) => {
  await nextTick();
  if (selectedDate.value.start && selectedDate.value.end) {
    const renewedReservationData = await window.fetchReservationData();

    if (renewedReservationData && renewedReservationData.hasOwnProperty('checkInTimes') && renewedReservationData.hasOwnProperty('checkOutTimes')) {
      setCheckInOutTimeSelectors(renewedReservationData.checkInTimes, renewedReservationData.checkOutTimes);
    }
  }
});
</script>
<style>
.center {
  text-align: center;
}
</style>
