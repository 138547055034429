<template>
    <div class="accommodation-list">
      <result-card
          @update-view-mode-with-focus="showResultOnMap"
          :result="result"
          v-for="result in results"
          :key="result.id"
      />
    </div>
    <div class="d-flex justify-content-center">
      <Pagination
          :pager="pager"
          @go-to-page="goToPage"
      ></Pagination>
    </div>
</template>

<script>
import ResultCard from "./ResultCard";
import Pagination from "../../Pagination";

export default {
  name: "ListView",
  components: {Pagination, ResultCard},
  props: {
    results: [],
    pager: {},
  },
  data() {
    return {

    }
  },
  methods: {
    showResultOnMap(result) {
      this.$emit('update-view-mode-with-focus', result)
    },
    goToPage(index) {
      this.$emit('go-to-page', index);
    }
  }
}
</script>

<style scoped>

</style>