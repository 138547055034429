<template>
  <div>
    <h5>{{ vueTranslator('common.upgrades_title') }} {{ this.domain }}</h5>
    <p>{{ vueTranslator('common.upgrades_description') }}</p>

    <div class="empty-state-message" v-if="!this.upgrades.length">
      {{ vueTranslator('common.no_upgrades') }}
    </div>

    <div v-for="upgrade in this.upgrades" class="upgrade-element">
      <div class="upgrade-description">
        <h5>{{ upgrade.name }}</h5>
        <span class="tag">
                <i class="icon-options"></i>
                {{ vueTranslator('common.upgrade') }}
              </span>
        <div v-html="upgrade.description"></div>
      </div>
      <div class="upgrade-price">
        <b>€ {{ upgrade.amount }}</b> / {{ upgrade.pricing_method }}
      </div>
    </div>
  </div>
</template>

<script>
import { vueTranslator } from '../../../mixins.js';
export default {
  name: "Upgrades",
  props: [
    'upgrades',
    'domain'
  ],
  data() {
    return {
      vueTranslator,
    };
  },
}
</script>

<style scoped>

</style>