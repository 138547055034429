<template>
  <section class="mt-5 price-calculator">
    <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
      <h2>{{ vueTranslator('common.prices') }}</h2>
      <div class="d-flex">
        <div class="mr-2">
          <small>{{ vueTranslator('common.group_type') }}</small>
          <div class="filter-element">
            <span class="select-icon green">
                <i class="icon-people"></i>
            </span>

            <Multiselect
                class="form-control form-control-lg form-control-solid price-year-filter-element with-icon"
                :options="this.typeOptions"
                v-model="this.filterType"
                @change="changeType"
            >
            </Multiselect>
          </div>
        </div>

        <div>
          <small>{{ vueTranslator('common.year') }}</small>
          <div class="filter-element">
            <span class="select-icon green">
                <i class="icon-calendar"></i>
            </span>

            <Multiselect
                class="form-control form-control-lg form-control-solid price-year-filter-element with-icon"
                :options="this.yearOptions"
                v-model="this.filterYear"
                @change="changeYear"
            >
            </Multiselect>
          </div>
        </div>
      </div>
    </div>

    <section class="tabs-component">
      <div class="tabs-headers d-none d-xl-flex">
        <div
            class="tabs-header"
            @click="currentActiveTab = 1"
            :class="{ 'active' : currentActiveTab === 1}"
        >
          {{ vueTranslator('common.general_prices') }}
        </div>
        <div
            class="tabs-header"
            @click="currentActiveTab = 2"
            :class="{ 'active' : currentActiveTab === 2}"
        >
          {{ vueTranslator('common.extra_costs' ) }}
        </div>
        <div
            class="tabs-header"
            @click="currentActiveTab = 3"
            :class="{ 'active' : currentActiveTab === 3}"
        >
          {{ vueTranslator('common.upgrades') }}
        </div>
      </div>
      <div class="tabs-headers d-flex d-xl-none">
        <div
            class="tabs-header"
            :class="{ 'active' : currentActiveTab === 1}"
        >
          {{ vueTranslator('common.general_prices') }}
        </div>
        <div
            class="tabs-header"
            :class="{ 'active' : currentActiveTab === 2}"
        >
          {{ vueTranslator('common.extra_costs' ) }}
        </div>
        <div
            class="tabs-header"
            :class="{ 'active' : currentActiveTab === 3}"
        >
          {{ vueTranslator('common.upgrades') }}
        </div>
      </div>
      <div class="control-panel d-flex d-xl-none">
        <button @click="currentActiveTab = (currentActiveTab - 1) < 1 ? 3 : (currentActiveTab - 1)">
          <i class="icon-arrow-left">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </button>
        <button @click="currentActiveTab = (currentActiveTab + 1) > 3 ? 1 : (currentActiveTab + 1)">
          <i class="icon-arrow-right">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </button>
      </div>
      <div class="tabs-content">
        <div class="tab-panel" :class="{ 'active' : currentActiveTab === 1}">
          <div class="d-block d-md-none">
            <GeneralPricesMobile
                :year="filterYear"
                :prices="prices"
                :exceptions="exceptions"
                :warranties="warranties"
                :priceTypeSelfCooking="priceTypeSelfCooking"
                :externalUrl = "externalUrl"
            />
          </div>
          <div class="d-none d-md-block">
            <GeneralPricesDesktop
                :year="filterYear"
                :prices="prices"
                :exceptions="exceptions"
                :warranties="warranties"
                :priceTypeSelfCooking="priceTypeSelfCooking"
                :externalUrl = "externalUrl"
            />
          </div>
        </div>
        <div class="tab-panel" :class="{ 'active' : currentActiveTab === 2}">
          <ExtraCosts :costs="costs"/>
        </div>
        <div class="tab-panel" :class="{ 'active' : currentActiveTab === 3}">
          <Upgrades
            :upgrades="upgrades"
            :domain="domain"
          />
        </div>
        <p v-if="notes.self_cooking && currentActiveTab === 1" class="mt-3">
          <strong>{{ vueTranslator('common.self_cooking') }}</strong> {{ notes.self_cooking }}
        </p>
        <p v-if="notes.full_board && currentActiveTab === 1" class="mt-3">
          <strong>{{ vueTranslator('common.full_board') }}</strong> {{ notes.full_board }}
        </p>

        <p v-if="bottomText && currentActiveTab === 1" class="mt-3">
          {{ generateBottomTextString }}
        </p>
        <p v-if="currentActiveTab === 2" class="mt-3">
          {{ vueTranslator('common.price_widget_extra_cost_message') }}
        </p>
      </div>
    </section>
  </section>
</template>

<script>
import GeneralPricesDesktop from '../components/_segments/AccommodationPricesWidget/GeneralPricesDesktop'
import GeneralPricesMobile from '../components/_segments/AccommodationPricesWidget/GeneralPricesMobile'
import ExtraCosts from '../components/_segments/AccommodationPricesWidget/ExtraCosts'
import Upgrades from '../components/_segments/AccommodationPricesWidget/Upgrades'
import Multiselect from '@vueform/multiselect'
import { vueTranslator } from '../mixins.js';

export default {
  name: 'AccommodationPricesWidget',
  components: {
    GeneralPricesDesktop,
    GeneralPricesMobile,
    ExtraCosts,
    Upgrades,
    Multiselect
  },
  props: [
    'initPrices',
    'initExceptions',
    'initWarranties',
    'initCosts',
    'initUpgrades',
    'domain',
    'endpoint',
    'translations',
    'initYears',
    'initPriceTypeSelfCooking',
    'initMinimumNights',
    'initNotes',
    'currentSelectedYear',
    'currentSelectedType',
    'bottomText',
    'externalPriceListUrl'
  ],
  data() {
    return {
      currentActiveTab: 1,
      filterType: this.currentSelectedType ? this.currentSelectedType : 'A',
      filterYear: this.formatYear(),
      externalUrl: this.externalPriceListUrl,
      prices: JSON.parse(this.initPrices),
      exceptions: JSON.parse(this.initExceptions),
      warranties: JSON.parse(this.initWarranties),
      costs: JSON.parse(this.initCosts),
      upgrades: JSON.parse(this.initUpgrades),
      years: JSON.parse(this.initYears),
      priceTypeSelfCooking: this.initPriceTypeSelfCooking ? this.initPriceTypeSelfCooking : 'price_per_night',
      minimumNights: parseInt(this.initMinimumNights),
      notes: JSON.parse(this.initNotes),
      vueTranslator,
    }
  },
  computed: {
    generateBottomTextString() {
      return this.bottomText.replace('{minimumNights}', this.minimumNights)
    },
    yearOptions() {
      return Object.values(this.years).sort(function(a, b) {
        return a - b;
      }).map(year => {
        return {label: year, value: year}
      })
    },
    typeOptions() {
      return [
        {label: vueTranslator('common.billingGroup.A'), value: 'A'},
        {label: vueTranslator('common.billingGroup.B'), value: 'B'},
        {label: vueTranslator('common.billingGroup.C'), value: 'C'},
        {label: vueTranslator('common.billingGroup.D'), value: 'D'},
        {label: vueTranslator('common.billingGroup.E'), value: 'E'}
      ]
    }
  },
  methods: {
    changeType (value) {
      this.filterType = value;
      this.fetchPricesByFilters();
    },
    changeYear (value) {
      this.filterYear = value;
      this.fetchPricesByFilters();
    },
    fetchPricesByFilters () {
      fetch(`${this.endpoint}?year=${this.filterYear}&type=${this.filterType}`)
          .then(response => response.json())
          .then(data => {
            this.prices = data.prices;
            this.exceptions = data.exceptions;
            this.warranties = data.warranties;
            this.costs = data.costs;
            this.upgrades = data.extras;
            this.priceTypeSelfCooking = data.pricing_type ? data.pricing_type.machine_name : 'price_per_night';
            this.minimumNights = data.minimum_nights;
            this.notes = data.notes;
            this.externalUrl = data.external_price_list_url;
          })
          .catch(error => console.log(error));
    },
    formatYear() {
      var dateParts = this.currentSelectedYear.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

      return this.currentSelectedYear ? dateObject.getFullYear() : new Date().getFullYear();
    },
  },
  mounted() {

  }
};
</script>

<style lang="scss" module>

</style>
