<template>
    <table class="table table-row-dashed">
        <thead>
        <tr>
            <th v-for="header in headers">
                {{ header }}
            </th>
            <th></th>
        </tr>
        </thead>
        <TransitionGroup name="list" tag="tbody">
            <tr v-for="(row, index) in rows" :key="row">
                <td v-for="column in row.columns">
                    {{ column }}
                </td>
                <td class="text-center">
                    <div class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-on:click="remove(row.removeCallback, index)">
                        X
                    </div>
                </td>
            </tr>
        </TransitionGroup>
    </table>

    <div v-if="rows.length === 0" class="mb-5">
        {{ vueTranslator('common.no_results') }}
    </div>

    <div class="d-flex align-content-end">
        <div class="form-group">
            <label class="form-label">{{ addLabel }}</label>
            <input class="form-control" v-model="addValue" type="text">
        </div>

        <div class="align-self-end ml-3">
            <button class="btn btn-primary" v-on:click="add">{{ vueTranslator('common.add') }}</button>
        </div>
    </div>

    <delete-confirm-dialogue ref="confirmDialogue"></delete-confirm-dialogue>

</template>

<script>
import axios from 'axios';
import DeleteConfirmDialogue from '../components/DeleteConfirmDialogue.vue'
import { vueTranslator } from '../mixins.js';
export default {
    name: "InteractiveTableWidget",
    components: { DeleteConfirmDialogue },
    props: {
        'tableHeaders': String,
        'tableRows': String,
        'addCallback': String,
        'addLabel': String,
    },
    data () {
        return {
            rows: JSON.parse(this.tableRows),
            headers: JSON.parse(this.tableHeaders),
            addValue: null,
            vueTranslator,
        }
    },
    methods: {
        remove (callback, index) {
            this.$refs.confirmDialogue.show().then(result => {
                if (result) {
                    axios.delete(callback).then(response => {
                        this.rows.splice(index, 1)
                    }).catch(error => {
                        toastr.error(error.response.data.detail ? error.response.data.detail : error);
                    })
                }
            })
        },
        add() {
            axios.post(this.addCallback, {value: this.addValue})
            .then(response => {
                this.rows.push(response.data);
                this.addValue = null;
            }).catch(error => {
                toastr.error(error.response.data.detail ? error.response.data.detail : error);
            })
        }
    },
}
</script>

<style scoped>
    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
</style>
