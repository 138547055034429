<template>
  <div>
    <div class="empty-state-message" v-if="!this.costs.length">
      {{ vueTranslator('common.no_extra_costs') }}
    </div>
    <table v-for="costGroup in this.costs">
      <tr>
        <th>
          <h5>{{ costGroup.group_name }}</h5>
        </th>
      </tr>
      <tr v-for="cost in costGroup.price_lists">
        <td><b>{{ cost.name }}</b> ({{ vueTranslator('common.unit.' + cost.unit) }}<span v-if="cost.is_daily">/{{ vueTranslator('common.night') }}</span>)</td>
        <td>{{ cost.price_with_currency_self_cooking }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { vueTranslator } from '../../../mixins.js';

export default {
  name: "ExtraCosts",
  props: [
    'costs'
  ],
  data() {
    return {
      vueTranslator,
    };
  },
}
</script>

<style scoped>

</style>