<template>
  <div>
    <div class="daily-overnight-element grid-layout pt-3">
      <div>
        <h4>{{ title }}</h4>
      </div>
      <div class="grid-span-2">
        <div v-if="accommodations !== undefined && getFilteredByLogisDecreet(accommodations).length > 1" class="d-flex justify-content-end">
          <label class="mr-2" for="flexSwitchCheckDefault">{{ vueTranslator('booking.statistics.manual_control') }}</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                   @change="changeControl($event)"
                   :value="true"
                   :checked="this.statistic['edit_mode'] === 'free_input'"
            >
          </div>
        </div>
      </div>

      <div class="sub-header">
        <b>{{ vueTranslator('booking.statistics.total') }}</b>
      </div>
      <div>
        <input class="form-control form-control-lg form-control-solid" type="number" min="0"
               :disabled="this.statistic['edit_mode'] === 'free_input'"
               @input="changeTotalPersonTillAge($event)"
               :value="this.statistic['persons_till_age30']">
      </div>
      <div>
        <input class="form-control form-control-lg form-control-solid" type="number" min="0"
               :disabled="this.statistic['edit_mode'] === 'free_input'"
               @input="changeTotalPersonAboveAge($event)"
               :value="this.statistic['persons_above_age30']">
      </div>
      <template v-for="(accommodation, index) in getFilteredByRatio(accommodations)">
          <div class="sub-header" :class="{'unaknowledged': null === accommodation.logis_decreet, 'locked': this.statistic['edit_mode'] === 'calculated'}">
            <i>{{ accommodation.name }}</i>
            <p>{{ vueTranslator('booking.statistics.logies_decreet') }}: {{accommodation.logis_decreet}}</p>
          </div>
          <div :class="{'hidden': null === accommodation.logis_decreet, 'calculation-sum-number-field': index !== 0, 'locked': this.statistic['edit_mode'] === 'calculated'}">
            <input :disabled="this.statistic['edit_mode'] === 'calculated'"
                   class="form-control form-control-sm form-control-solid" type="number" min="0"
                   @input="changeTotalPersonTillAgeOfAccommodation($event, accommodation)"
                   :value="this.getAmountOfPersonsValue(accommodation.id, 'persons_till_age30')">
          </div>
          <div :class="{'hidden': null === accommodation.logis_decreet, 'calculation-sum-number-field': index !== 0, 'locked': this.statistic['edit_mode'] === 'calculated'}">
            <input :disabled="this.statistic['edit_mode'] === 'calculated'"
                   class="form-control form-control-sm form-control-solid " type="number" min="0"
                   @input="changeTotalPersonAboveAgeOfAccommodation($event, accommodation)"
                   :value="this.getAmountOfPersonsValue(accommodation.id, 'persons_above_age30')">
          </div>
      </template>
    </div>
  </div>
</template>

<script>
import { vueTranslator } from '../mixins.js';
export default {
  props: [
    'statistic', 'accommodations', 'title'
  ],
  data() {
    return {
      vueTranslator,
    }
  },
  methods: {
    getFilteredByRatio(accommodations) {
      return accommodations.filter(a => 0 !== a.ratio);
    },
    getFilteredByLogisDecreet(accommodations) {
      return accommodations.filter(a => null !== a.logis_decreet);
    },
    getAmountOfPersonsValue(accommodationId, property) {
      const splitOvernigtStatistic = this.statistic.split_overnight_statistics.find(s => s.accommodation === accommodationId);

      if (splitOvernigtStatistic !== undefined) {
        return splitOvernigtStatistic[property];
      }

      return 0;
    },
    changeControl (event) {
      const newStat = { ...this.statistic, edit_mode: event.target.checked ? 'free_input' : 'calculated' };
      this.pushChanges(newStat);
    },
    changeTotalPersonTillAge (event) {
      let amountValue = parseInt(event.target.value);
      if (!amountValue) {
        amountValue = 0;
      }

      let newAccommodationStats = this.statistic.split_overnight_statistics.map(accommodation => {
        const calculation = Math.round(amountValue * accommodation.ratio);
        return { ...accommodation, 'persons_till_age30': calculation }
      });

      if(newAccommodationStats.length > 1) {
        const newAccommodationStatsWithoutLast = [...newAccommodationStats];
        newAccommodationStatsWithoutLast.pop();
        //newAccommodationStats[newAccommodationStats.length - 2]['persons_till_age30'] = amountValue - ((newAccommodationStats.length - 2) * newAccommodationStats[0]['persons_till_age30']);
        const totalWithoutLastAccommodation = newAccommodationStatsWithoutLast.reduce((partialSum, acco) => partialSum + acco.persons_till_age30, 0)
        newAccommodationStats[newAccommodationStats.length - 1]['persons_till_age30'] = amountValue - totalWithoutLastAccommodation;
      }

      const newStat = {
        ...this.statistic,
        persons_till_age30: amountValue,
        split_overnight_statistics: newAccommodationStats
      };
      this.pushChanges(newStat);
    },
    changeTotalPersonAboveAge (event) {
      let amountValue = parseInt(event.target.value);
      if (!amountValue) {
        amountValue = 0;
      }

      let newAccommodationStats = this.statistic.split_overnight_statistics.map(accommodation => {
        return {...accommodation, persons_above_age30: Math.round(amountValue * accommodation.ratio) }
      });

      if(newAccommodationStats.length > 1) {
        const newAccommodationStatsWithoutLast = [...newAccommodationStats];
        newAccommodationStatsWithoutLast.pop();
        //newAccommodationStats[newAccommodationStats.length - 2]['persons_above_age30'] = amountValue - ((newAccommodationStats.length - 2) * newAccommodationStats[0]['persons_above_age30']);
        const totalWithoutLastAccommodation = newAccommodationStatsWithoutLast.reduce((partialSum, acco) => partialSum + acco.persons_above_age30, 0)
        newAccommodationStats[newAccommodationStats.length - 1]['persons_above_age30'] = amountValue - totalWithoutLastAccommodation;
      }

      const newStat = {
        ...this.statistic,
        persons_above_age30: amountValue,
        split_overnight_statistics: newAccommodationStats
      };
      this.pushChanges(newStat);
    },
    changeTotalPersonTillAgeOfAccommodation (event, accommodation) {
      let amountValue = parseInt(event.target.value);
      if (!amountValue) {
        amountValue = 0;
      }

      const newAccommodationStats = this.statistic.split_overnight_statistics.map(acco => {
        if (acco.accommodation === accommodation.id) {
          return {...acco, persons_till_age30: amountValue }
        }
        return acco
      });

      const sumOfAccommodationProps = newAccommodationStats.reduce((partialSum, acco) => partialSum + acco.persons_till_age30, 0)

      const newStat = {
        ...this.statistic,
        persons_till_age30: sumOfAccommodationProps,
        split_overnight_statistics: newAccommodationStats
      };
      this.pushChanges(newStat);
    },
    changeTotalPersonAboveAgeOfAccommodation (event, accommodation) {
      let amountValue = parseInt(event.target.value);
      if (!amountValue) {
        amountValue = 0;
      }

      const newAccommodationStats = this.statistic.split_overnight_statistics.map(acco => {
        if (acco.accommodation === accommodation.id) {
          return {...acco, persons_above_age30: amountValue }
        }
        return acco
      });

      const sumOfAccommodationProps = newAccommodationStats.reduce((partialSum, acco) => partialSum + acco.persons_above_age30, 0)

      const newStat = {
        ...this.statistic,
        persons_above_age30: sumOfAccommodationProps,
        split_overnight_statistics: newAccommodationStats
      };
      this.pushChanges(newStat);
    },
    pushChanges (newStat) {
      this.$emit("newChange", newStat)
    }
  }
}
</script>

<style scoped>

</style>
