<template>
  <div class="facet-filter">
    <div class="filter-head"  v-bind:class="{ closed: !facetOpen }" v-on:click="facetOpen = !facetOpen">
      <div>
<!--        <i class="mr-2 " :class="icon"></i>-->
        {{ title }}
      </div>

      <i class="toggler icon-caret-down"  v-bind:class="{ 'rotate-180': facetOpen }"></i>
    </div>
    <div class="filter-body" v-bind:class="{ closed: !facetOpen }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Facet",
  props: {
    icon: '',
    title: ''
  },
  data() {
    return {
      facetOpen: true,
    }
  }
}
</script>

<style scoped>

</style>