<template>
  <div class="facet-element">
    <label v-for="option in options" @change="updateContext(option)" class="radiobox">
      {{ option.label }}
      <input :checked="isInContext(option)" :value="option.key" :name="name" type="radio">
      <span class="checkmark"></span>
    </label>
  </div>
</template>
<script>
import { store } from '../../../../store.js'

export default {
  name: "SelectType",
  props: ['name', 'options'],
  data() {
    return {
      store,
    }
  },
  methods: {
    isInContext(option) {
      if(!this.store.context.hasOwnProperty(this.name) || this.store.context[this.name] === null) {
        return false;
      }

      return store.context[this.name] === option.key;
    },
    updateContext(option) {
       if(!this.store.context.hasOwnProperty(this.name)) {
         console.info('Could not find filter ', this.name);
         return
       }

       this.store.context[this.name] = option.key

       this.store.updateSearchResults();
    }
  }
}
</script>

<style scoped>

</style>
