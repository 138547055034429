<template>
    <popup-modal ref="popup">
        <h2 style="margin-top: 0">{{ title }}</h2>
        <p>{{ message }}</p>
        <div class="d-flex justify-content-between">
            <button class="btn btn-link" @click="_cancel">{{ cancelButton }}</button>
            <button class="btn btn-danger" @click="_confirm">{{ okButton }}</button>
        </div>
    </popup-modal>
</template>

<script>
import { vueTranslator } from '../mixins.js';
import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',
    components: { PopupModal },
    data: () => ({
        title: vueTranslator('common.confirmation.delete.title'),
        message: vueTranslator('common.confirmation.delete.message'),
        okButton: vueTranslator('common.confirmation.delete.button'),
        cancelButton: vueTranslator('common.cancel'),

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
        vueTranslator,
    }),

    methods: {
        show(opts = {}) {
            if (opts.title) {
                this.title = opts.title
            }
            if (opts.message) {
                this.message = opts.message
            }
            if (opts.okButton) {
                this.okButton = opts.okButton
            }
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
        },
    },
}
</script>
