export const filterTypes = {
    CHECKBOXES: 'checkboxes',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    NUMBER: 'number',
}

export const sortingTypes = {
    CAPACITY_ASC: { "capacity": "asc" },
    CAPACITY_DESC: { "capacity": "desc" },
    SCORE_DESC: { "_score": "desc" },
}

export const viewModes = {
    LIST: 'view-mode-list',
    MAP: 'view-mode-map',
}