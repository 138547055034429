<template>
  <div>
    <table>
      <tr>
        <th colspan="2">
          <h5>{{ vueTranslator('common.general_prices') }} ({{ this.year }})</h5>
        </th>
      </tr>
      <tr v-if="hasPricesSelfCooking">
        <td colspan="2" class="mobile-meal-type-header">{{ vueTranslator('common.self_cooking') }} <span>({{ vueTranslator(`common.${priceTypeSelfCooking}`)}})</span></td>
      </tr>
      <tr v-for="price in this.prices" v-if="hasPricesSelfCooking">
        <td><b>{{ price.name }}</b></td>
        <td>{{ price.price_with_currency_self_cooking }} <span v-if="price.minimum_guests_self_cooking">- (min. {{ price.minimum_guests_self_cooking }} pers.)</span></td>
      </tr>
      <tr v-if="hasPricesFullBoard">
        <td colspan="2" class="mobile-meal-type-header">{{ vueTranslator('common.full_board') }}* <span>({{ vueTranslator('common.price_per_person_per_day') }})</span></td>
      </tr>
      <tr v-for="price in this.prices" v-if="hasPricesFullBoard">
        <td><b>{{ price.name }}</b></td>
        <td>{{ price.price_with_currency_full_board }} <span v-if="price.minimum_guests_full_board">- (min. {{ price.minimum_guests_full_board }} pers.)</span></td>
      </tr>
    </table>
    <table v-if="this.exceptions.length">
      <tr>
        <th colspan="2">
          <h5>{{ vueTranslator('common.exceptions') }}</h5>
        </th>
      </tr>
      <tr v-if="hasExceptionSelfCooking">
        <td colspan="2" class="mobile-meal-type-header">{{ vueTranslator('common.self_cooking') }} <span>({{ vueTranslator(`common.${priceTypeSelfCooking}`)}})</span></td>
      </tr>
      <tr v-for="exception in this.exceptions" v-if="hasExceptionSelfCooking">
        <td class="d-flex flex-column"><b>{{ exception.name }}</b><span>{{ exception.unit }}</span></td>
        <td>{{ exception.price_with_currency_self_cooking }}</td>
      </tr>
      <tr v-if="hasExceptionFullBoard">
        <td colspan="2" class="mobile-meal-type-header">{{ vueTranslator('common.full_board') }}* <span>({{ vueTranslator('common.price_per_person_per_day') }})</span></td>
      </tr>
      <tr v-for="exception in this.exceptions" v-if="hasExceptionFullBoard">
        <td class="d-flex flex-column"><b>{{ exception.name }}</b><span>{{ exception.unit }}</span></td>
        <td>{{ exception.price_with_currency_full_board }}</td>
      </tr>
    </table>
    <p v-if="externalUrl" class="mt-3">
      <a :href="externalUrl" target="__blank">{{ vueTranslator('common.full_board_price_link_label') }} ({{ this.year }})</a>
    </p>
<!--    <table v-if="this.warranties.length">-->
<!--      <tr>-->
<!--        <th colspan="2">-->
<!--          <h5>{{ vueTranslator('common.warranty') }}</h5>-->
<!--        </th>-->
<!--      </tr>-->
<!--      <tr v-if="hasWarrantySelfCooking">-->
<!--        <td colspan="2" class="mobile-meal-type-header">{{ vueTranslator('common.self_cooking') }}</td>-->
<!--      </tr>-->
<!--      <tr v-for="warranty in this.warranties" v-if="hasWarrantySelfCooking">-->
<!--        <td><b>{{ warranty.name }}</b></td>-->
<!--        <td>{{ warranty.price_with_currency_self_cooking }}</td>-->
<!--      </tr>-->
<!--      <tr v-if="hasWarrantyFullBoard">-->
<!--        <td colspan="2" class="mobile-meal-type-header">{{ vueTranslator('common.full_board') }}*</td>-->
<!--      </tr>-->
<!--      <tr v-for="warranty in this.warranties" v-if="hasWarrantyFullBoard">-->
<!--        <td><b>{{ warranty.name }}</b></td>-->
<!--        <td>{{ warranty.price_with_currency_full_board }}</td>-->
<!--      </tr>-->
<!--    </table>-->
  </div>
</template>

<script>
import { vueTranslator } from '../../../mixins.js';

const isFilteredArrayFilled = (array, value) => {
  return array.filter(e => e[value]).length > 0;
}

export default {
  name: "GeneralPricesMobile",
  props: [
    'year',
    'warranties',
    'exceptions',
    'prices',
    'priceTypeSelfCooking',
    'externalUrl'
],
  computed: {
    hasPricesFullBoard() { return isFilteredArrayFilled(this.prices, 'price_full_board') },
    hasPricesSelfCooking() { return isFilteredArrayFilled(this.prices, 'price_self_cooking') },
    hasExceptionFullBoard() { return isFilteredArrayFilled(this.exceptions, 'price_full_board') },
    hasExceptionSelfCooking() { return isFilteredArrayFilled(this.exceptions, 'price_self_cooking') },
    hasWarrantyFullBoard() { return isFilteredArrayFilled(this.warranties, 'price_full_board') },
    hasWarrantySelfCooking() { return isFilteredArrayFilled(this.warranties, 'price_self_cooking') },
  },
  data() {
    return {
      vueTranslator,
    };
  },
}
</script>
