<template>
  <div class="facet-element">
    <div class="row mb-2 align-items-center">
      <div class="col-8">
        {{ label }}
      </div>
      <div class="col-4">
        <input class="form-control" @input="updateContext"  :value="store.context[this.name]" type="number">
      </div>
    </div>
  </div>
</template>
<script>
import { store } from '../../../../store.js'

export default {
  name: "NumberType",
  props: ['name', 'label'],
  data() {
    return {
      store,
    }
  },
  methods: {
    updateContext(event) {
       if(!this.store.context.hasOwnProperty(this.name)) {
         console.info('Could not find filter ', this.name);
         return
       }

       this.store.context[this.name] = parseInt(event.target.value);

       this.store.updateSearchResults();
    }
  }
}
</script>

<style scoped>

</style>
