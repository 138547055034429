const vueTranslator = (translationString) => {
    const translations = JSON.parse(localStorage.getItem('translations'))

    if (!translations) {
        let translation = null;
        fetch(window.TRANSLATION_JSON)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('translations', JSON.stringify(data));
                translation = getTransValue(data, translationString);
            })
            .catch(error => console.log(error));

        return translation;
    } else {
        return getTransValue(translations, translationString);
    }
}

const getTransValue = (translations, translationString) => {
    const translationArray = translationString.split('.');
    let currentKey = '';

    translationArray.forEach((key) => {
        currentKey = !currentKey ? translations[key] : currentKey[key]
    })

    return currentKey;
}

export {
    vueTranslator
}
