<template>
  <star-rating
      rounded-corners="true"
      star-size="25"
      v-model:rating="value"
  />
  <input type="hidden" :name="fieldName" :value="value">
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  name: "StarSelectorWidget",
  props: ['fieldName', 'initValue'],
  data() {
    return {
      value: 0
    }
  },
  mounted() {
    this.value = this.initValue ? parseInt(this.initValue) : 0;
  },
  components: {
    StarRating
  }
}
</script>

<style scoped>

</style>