<template>
  <div>
    <div class="statistics-headers grid-layout">
      <div></div>
      <div class="min-30">
        <h5>{{ vueTranslator('booking.statistics.till_age_30') }}</h5>
      </div>
      <div class="plus-30">
        <h5>{{ vueTranslator('booking.statistics.above_age_30') }}</h5>
      </div>
    </div>

    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
             @click="changeViewMode()"
             :value="true"
             :checked="this.viewMode === 'full'"
      >
      <label class="mr-2" for="flexSwitchCheckDefault">{{ vueTranslator('booking.statistics.adjust_per_night') }}</label>
    </div>

    <div v-if="viewMode === 'compact' && this.statistics.length">
      <DailyOvernightDay :statistic="this.statistics[0]"
                         :title="vueTranslator('common.general')"
                         :accommodations="accommodations"
                         @new-change="pushGeneralChanges"
      ></DailyOvernightDay>
    </div>
    <div v-if="viewMode === 'full'" class="daily-overnight-list">
      <DailyOvernightDay v-for="statistic in statistics"
                         :statistic="statistic"
                         :title="statistic.full_day"
                         :accommodations="accommodations"
                         @new-change="pushChanges"
      ></DailyOvernightDay>
    </div>

    <p v-if="showPersonsError" class="small text-red text-danger">
      {{ vueTranslator('statistics.form.no_persons_error') }}
    </p>

    <div class="d-flex justify-content-center w-100 mt-2">
      <button @click="checkCapacityBeforePost()" class="btn btn-sm vertical-stepper-next-btn">
        {{ vueTranslator(lastStep === 'true' ? 'common.save' : 'common.next_step') }}
      </button>

    </div>
  </div>
</template>

<script>
import DailyOvernightDay from "../components/daily-overnight-day";
import { vueTranslator } from '../mixins.js';

export default {
  components: {DailyOvernightDay},
  props: ['endpoint', 'lastStep', 'previousStepEndpoint', 'capacity'],
  data() {
    return {
      accommodations: [],
      editMode: "calculated",
      viewMode: "compact",
      statistics: [],
      showPersonsError: false,
      vueTranslator,
    };
  },
  methods: {
    fetchStatisticFields() {
      fetch(this.endpoint)
          .then(response => response.json())
          .then(data => {
            this.accommodations = data.accommodations;
            this.statistics = data.statistics;
            this.editMode = data.edit_mode;
            this.viewMode = data.view_mode;
          })
          .catch(error => console.log(error));
    },
    checkCapacityBeforePost(endpoint, previous) {
      const participantsExceedCapacity = this.statistics.some(statistic => statistic.persons_above_age30 + statistic.persons_till_age30 > this.capacity);

      if (participantsExceedCapacity && this.capacity !== null && "" !== this.capacity) {
        Swal.fire({
          title: 'Ben je zeker?',
          text: 'Het aantal deelnemers is groter dan de totale capaciteit.',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonColor: '#57b8ff',
        }).then((result) => {
          if (result.isConfirmed) {
            this.postStatistics(endpoint, previous);
          }
        });
      } else {
        this.postStatistics(endpoint, previous);
      }
    },
    postStatistics (endpoint, previous) {
      const finalisingBooking = this.endpoint.includes('finalise/statistics');
      const hasNoPersons = this.statistics.filter(s => s.persons_till_age30 === 0 && s.persons_above_age30 === 0);
      if (true !== finalisingBooking && true !== previous && hasNoPersons.length > 0) {
        this.showPersonsError = true;

        return;
      }

      this.showPersonsError = false;

      if (endpoint === undefined) {
        endpoint = this.endpoint;
      }

      fetch(endpoint, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(
            {
              accommodations: this.accommodations,
              edit_mode: this.editMode,
              view_mode: this.viewMode,
              statistics: this.statistics
            }
        )
      }).then(response => response.json())
        .then(res => {
          if(res.redirect_url) {
            window.location.href = res.redirect_url;
          }
      });
    },
    pushChanges (event) {
      this.statistics = this.statistics.map(statistic => {
        if(statistic.day === event.day) {
          return event;
        }

        return statistic;
      });
    },
    pushGeneralChanges (event) {
      this.statistics = this.statistics.map(statistic => {
       return {
         ...event,
         day: statistic.day,
         full_day: statistic.full_day
        }
      });
    },
    changeViewMode() {
      this.viewMode = this.viewMode === 'compact' ? 'full' : 'compact'

      if (this.viewMode === 'compact') {
        const [first, ...rest] = this.statistics;

        rest.forEach(statistic => {
          statistic.persons_till_age30 = first.persons_till_age30;
          statistic.persons_above_age30 = first.persons_above_age30;
          statistic.split_overnight_statistics = first.split_overnight_statistics;
        })
      }
    }
  },
  mounted() {
    const self = this;

    const previousStepEl = document.querySelector('.js-previous-step');
    if (previousStepEl) {
      previousStepEl.addEventListener('click', function(e) {
        e.preventDefault();

        self.postStatistics(self.previousStepEndpoint, true);
      });
    }

    this.fetchStatisticFields();

  },
};
</script>

<style>
</style>
