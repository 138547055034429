<template>
  <div class="mb-5" ref="root" >
    <h3 class="mb-3" v-text="vueTranslator('common.period')"></h3>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <div class="calendar-multi-month d-block">
          <input @change="fetchAvailableAccommodations" class="form-control form-control-lg form-control-solid flatpickr flatpickr-input m-0 p-0" type="hidden" style="min-height: 0; height: 0" v-bind:value="dateRange" />
        </div>
      </div>
      <input type="hidden" id="create_option_checkInDate" name="create_option[checkInDate]" readonly="readonly" v-bind:value="startDate">
      <input type="hidden" id="create_option_checkOutDate" name="create_option[checkOutDate]" readonly="readonly" v-bind:value="endDate">
    </div>
    <div class="row mb-6">
      <div class="col-6">
        <input class="timepickr flatpickr-input" type="text" id="create_option_checkInTime" name="create_option[checkInTime]" required="required" readonly="readonly" v-bind:value="startTime">
      </div>
      <div class="col-6">
        <input class="timepickr flatpickr-input" type="text" id="create_option_checkOutTime" name="create_option[checkOutTime]" required="required" readonly="readonly" v-bind:value="endTime">
      </div>
    </div>

    <h3 class="mb-3" v-text="vueTranslator('common.accommodation')"></h3>
    <div id="accommodation-list">
      <label v-for="option in options" class="d-flex flex-stack mb-3 cursor-pointer">
        <span class="d-flex align-items-center me-2">
            <span class="mr-3">
                <span v-if="option.type === 'building'" class="boxed-icon lightgreen medium mr-3">
                    <i class="icon-house icon-20"></i>
                </span>

                <span v-if="option.type === 'camping'" class="boxed-icon lightgreen medium mr-3">
                    <i class="icon-tent icon-20"></i>
                </span>

                <span v-if="option.type === 'combination'" class="boxed-icon lightorange medium mr-3">
                    <i class="icon-combo"></i>
                </span>
            </span>
            <span class="d-flex flex-column">
                <span v-text="option.label" class="fw-bolder text-gray-800 text-hover-primary fs-5"></span>
                <span>
                    <span v-for="(item, index) in option.capacities">
                        {{ item.periodName }}: {{ item.amount}}
                        <span v-if="index !== (option.capacities.length - 1)"> | </span>
                    </span>
                </span>
            </span>
        </span>
        <span class="form-check form-check-custom form-check-solid">
            <input class="form-check-input" v-bind:checked="option.checked" v-bind:name="accommodationFieldName" v-bind:value="option.value" type="radio" />
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { vueTranslator } from '../mixins.js';

export default {
  name: "AccommodationAvailabilityWidget",
  props: [
    'jsonOptions',
    'accommodationFieldName',
    'checkInDate',
    'checkOutDate',
    'checkInTime',
    'checkOutTime',
    'callbackUrl'
  ],
  data() {
    return {
      options: JSON.parse(this.jsonOptions),
      loader: null,
      dateRange: '',
      startDate: this.checkInDate,
      endDate: this.checkOutDate,
      startTime: this.checkInTime,
      endTime: this.checkOutTime,
      vueTranslator,
    };
  },
  mounted() {
    this.dateRange = this.startDate !== '' && this.endDate !== '' ? `${this.startDate} to ${this.endDate}` : '';
    this.setLoader();
  },
  methods: {
    fetchAvailableAccommodations (event) {
      const rawEventRangeValue = event.target.value.split(' t/m ');

      // Only accept a event with a start AND end date.
      if (rawEventRangeValue.length < 2) {
        return;
      }

      this.startDate = rawEventRangeValue[0]
      this.endDate = rawEventRangeValue[1]

      const checkInDateInput = document.querySelector('#create_option_checkInDate');
      checkInDateInput.value = this.startDate;
      const checkOutDateInput = document.querySelector('#create_option_checkOutDate');
      checkOutDateInput.value = this.endDate;

      const url = new URL(this.callbackUrl);
      url.searchParams.append('checkInDate', this.startDate);
      url.searchParams.append('checkOutDate', this.endDate);

      if (null !== this.loader) {
        this.loader.block();
      }

      fetch(url.href)
          .then(response => response.json())
          .then(data => this.options = data)
          .then(data => {
            if (null !== this.loader) {
              this.loader.release();
            }
          })
          .catch(error => console.log(error));
    },
    setLoader () {
      this.loader = new KTBlockUI(this.$el.querySelector('#accommodation-list'), {
        message: `<div class="blockui-message"><span class="spinner-border text-primary"></span>${vueTranslator('common.fetching_accommodations')}...</div>`,
      });
    }
  }
};
</script>