<template>
  <div class="accommodation-map">
    <div id="map"></div>
  </div>
</template>

<script>
import { vueTranslator } from '../../../mixins.js';
import {store} from "../../../store";

export default {
  name: "MapView",
  props: {
    results: [],
  },
  data() {
    return {
      vueTranslator,
      store,
    }
  },
  mounted() {
    var platform = new H.service.Platform({
      apikey: window.apikey
    });

    var defaultLayers = platform.createDefaultLayers();

    document.getElementById('map').style.height = `${document.getElementById('map').offsetWidth}px` ;

    window.map = new H.Map(document.getElementById('map'),
        defaultLayers.vector.normal.map,{
          center: { lat: 51.0753416, lng: 4.4472116 },
          zoom: 8,
          pixelRatio: window.devicePixelRatio || 1
        });
    window.map.getViewPort().setPadding(50, 50, 50, 50);

    window.addEventListener('resize', () => window.map.getViewPort().resize());

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(window.map));

    window.ui = H.ui.UI.createDefault(window.map, defaultLayers);

    this.addInfoBubbles(window.map, window.ui)

    if(this.store.focusOnDomain && this.store.focusOnDomain.address.coordinates) {
      window.map.setCenter(this.focusedDomainCoords);
    }
  },
  computed: {
    focusedDomainCoords() {
      return {lat: parseFloat(this.store.focusOnDomain.address.coordinates.lat), lng: parseFloat(this.store.focusOnDomain.address.coordinates.lng)}
    }
  },
  methods: {
    addMarkerToGroup (group, coordinate, html) {
      const marker = this.createDomMarker(coordinate);
      marker.setData(html);
      group.addObject(marker);

      if(this.store.focusOnDomain && this.focusedDomainCoords.lat === coordinate.lat && this.focusedDomainCoords.lng === coordinate.lng) {
        marker.dispatchEvent('tap');
      }
    },
    getAccommodationCountFromResult(result) {
      return result.accommodations.filter(acco => false === acco.combination).length
    },
    getCombinationCountFromResult(result) {
      return result.accommodations.filter(acco => true === acco.combination).length
    },
    addInfoBubbles(map, ui) {
      const group = new H.map.Group();

      map.addObject(group);

      group.addEventListener('tap', function (evt) {
        // removing previous marker window
        document.querySelectorAll('.H_ib_top.H_el').forEach(e => e.remove());

        const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
          content: evt.target.getData()
        });

        map.setCenter({lat: evt.target.getGeometry().lat, lng: evt.target.getGeometry().lng});

        ui.addBubble(bubble);
      }, false);

      this.results.forEach((result) => {
        const coords = {lat: parseFloat(result.coordinates.lat), lng: parseFloat(result.coordinates.lon)};

        if(!coords) {
          return;
        }

        if(!coords.lat || !coords.lng) {
          return;
        }

        this.addMarkerToGroup(group, coords,
            `
          <div class="map-domain-teaser">
            <img src="${result.cover_image}" alt="">
            <div class="teaser-content">
              <a href="${result.domain_url}"><h6>${result.domain_name}</h6></a>
              <div class="map-domain-accommodation-list-teaser">
                <div class="map-domain-accommodations-teaser">
                  <div class="d-flex align-items-center">
                     <div class="boxed-icon green mr-2">
                       <i class="icon-house"></i>
                     </div>
                     ${vueTranslator('common.accommodations')}
                  </div>
                  <div class="availability-numbers">
                    <span class="${result.accommodations_count ? 'text-green' : ''}">${result.accommodations_count}</span>
                  </div>
                </div>
                <div class="map-domain-accommodations-teaser">
                  <div class="d-flex align-items-center">
                     <div class="boxed-icon orange mr-2">
                       <i class="icon-combo"></i>
                     </div>
                     ${vueTranslator('common.combinations')}
                  </div>
                  <div class="availability-numbers">
                     <span class="${result.combinations_count ? 'text-green' : ''}">${result.combinations_count}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `);
      })

      map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox()
      });
    },
    createDomMarker (coords) {
      var outerElement = document.createElement('div'),
          innerElement = document.createElement('div');

      outerElement.style.userSelect = 'none';
      outerElement.style.webkitUserSelect = 'none';
      outerElement.style.msUserSelect = 'none';
      outerElement.style.mozUserSelect = 'none';
      outerElement.style.cursor = 'default';

      innerElement.style.color = '#FE8361';
      innerElement.style.backgroundColor = 'white';
      innerElement.style.borderRadius = '50%';
      innerElement.style.lineHeight = '12px'
      innerElement.style.display = 'flex'
      innerElement.style.justifyContent = 'center'
      innerElement.style.alignItems = 'center'


      innerElement.style.width = '40px';
      innerElement.style.height = '40px';

      // add negative margin to inner element
      // to move the anchor to center of the div
      innerElement.style.marginTop = '-20px';
      innerElement.style.marginLeft = '-20px';

      outerElement.appendChild(innerElement);

      // Add text to the DOM element
      innerElement.innerHTML = '<i class="icon-house"></i>';

      //create dom icon and add/remove opacity listeners
      var domIcon = new H.map.DomIcon(outerElement, {});

      return new H.map.DomMarker( coords , {
        icon: domIcon
      });
    }
  },
  watch: {
    results(newResults, oldResults) {
      window.map.removeObjects(window.map.getObjects ())
      this.addInfoBubbles(window.map, window.ui)
    }
  }
}
</script>

<style scoped>

</style>