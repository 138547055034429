<template>
  <div class="accommodation-sorting">
    <div class="small-select mr-2">
      <Multiselect
          class="form-control form-control-lg form-control-solid"
          :options="this.sortingOptions"
          v-model="this.store.sorting"
          @change="changeSorting"
      >
      </Multiselect>
    </div>
    <div class="small-select">
      <Multiselect
          class="form-control form-control-lg form-control-solid"
          :options="this.viewModeOptions"
          v-model="this.store.viewMode"
          @change="changeViewMode"
      >
      </Multiselect>
    </div>
  </div>
</template>

<script>
import { store } from '../../../store.js'
import {sortingTypes, viewModes} from '../../../variables.js'
import Multiselect from '@vueform/multiselect'
import { vueTranslator } from '../../../mixins.js';
export default {
  name: "Sorting",
  components: {
    Multiselect
  },
  props: [
    'viewMode'
  ],
  data() {
    return {
      store,
      sortingTypes,
      viewModes,
      sorting: 'SCORE_DESC',
      vueTranslator,
    }
  },
  computed: {
    viewModeOptions() {
      return [
        {value: this.viewModes.LIST, label: vueTranslator('common.view_list')},
        {value: this.viewModes.MAP, label: vueTranslator('common.view_map')}
      ];
    },
    sortingOptions() {
      return [
        {value: this.sortingTypes.SCORE_DESC, label: vueTranslator('common.score_desc')},
        {value: this.sortingTypes.CAPACITY_DESC, label: vueTranslator('common.capacity_desc')},
        {value: this.sortingTypes.CAPACITY_ASC, label: vueTranslator('common.capacity_asc')},
      ];
    }
  },
  methods: {
    changeSorting(value) {
      this.store.sorting = value;
      this.store.updateSearchResults();
    },
    changeViewMode(value) {
      if(this.viewModes.LIST === value) {
        this.store.focusOnDomain = null;
      }
      this.store.viewMode = value;
      this.store.updateSearchResults();
    }
  }
}
</script>

<style scoped>

</style>
