import { reactive } from 'vue'
import axios from 'axios';
import { sortingTypes, viewModes } from './variables.js'

export const store = reactive({
    init: true,
    results: [],
    facets: [],
    mapMarkers: [],
    context: {},
    sorting: sortingTypes.SCORE_DESC,
    pager: {},
    page: 1,
    viewMode: viewModes.LIST,
    domainController: new AbortController(),
    markerController: new AbortController(),
    domainUrlTemplate: '',
    accommodationUrlTemplate: '',
    mapUrl: '',
    focusOnDomain: null,
    showLoader: false,
    totalResultsCount: 0,
    generateDomainUrl(slug) {
        return this.domainUrlTemplate.replace('__REPLACE__', slug)
    },
    generateAccommodationUrl(slug) {
        return this.accommodationUrlTemplate.replace('__REPLACE__', slug)
    },
    updateSearchResults() {
        if (this.viewMode === viewModes.MAP) {
            this.fetchMapMarkers();
        } else {
            this.fetchDomains();
        }
    },
    fetchMapMarkers() {
        this.markerController.abort();
        this.markerController = new AbortController();

        axios.post(`/${window.LOCALE}/api/accommodation/search/map`, {
            ...this.context,
            order: this.sorting,
            page: this.page
        }, { signal: this.markerController.signal })
            .then(data => {
                this.mapMarkers = data.data.items;
                this.totalResultsCount = data.data.items.length;
            })
            .catch(error => {
                console.log(error)
            })
    },
    fetchDomains() {
        this.domainController.abort();
        this.domainController = new AbortController();
        this.showLoader = true;

        axios.post(`/${window.LOCALE}/api/accommodation/search`, {
            ...this.context,
            order: this.sorting,
            page: this.page
        }, { signal: this.domainController.signal })
            .then(data => {
                this.results = Object.values(data.data.items);
                this.pager = data.data.pager;
                this.facets = data.data.facets;
                this.context = data.data.context;
                this.totalResultsCount = this.pager.totalItemCount

                this.showLoader = false;
            })
            .then(data => {
                this.syncContextWithUrl();
                this.init = false
            })
            .catch(error => {
                console.log(error)
            })
    },
    syncContextWithUrl() {
        let params = new URLSearchParams(window.location.search)

        for (const [key, value] of Object.entries(this.context)) {

            if(Array.isArray(value)) {
                value.forEach(val => {
                    params.append(`${key}[]`, val);
                });
                continue;
            }

            if (typeof value === 'object') {
                continue;
            }

            if(value !== null) {
                params.append(key, value);
            }
        }

        const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?${params.toString()}`;
        window.history.pushState({ path: newurl }, '', newurl);
    }
})
